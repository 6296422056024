import { Moment } from 'moment';
import { EnumType } from 'typescript';

export type ApiClient = {
  freeHardware: (params: {
    hardwareServer: Mailbox['identifier'];
    serialNumber: Hardware['serialNumber'];
  }) => Promise<unknown>;
  listProxyManagers: () => Promise<ProxyManager[]>;
  listHardware: () => Promise<Hardware[]>;
  reserveHardware: (params: {
    hardwareServer: Mailbox['identifier'];
    serialNumber: Hardware['serialNumber'];
  }) => Promise<unknown>;
  downloadProxyManager: () => Promise<DownloadableFile>;
  selectHardware: (params: {
    hardwareServer: Mailbox['identifier'];
    serialNumber: Hardware['serialNumber'];
    proxyManager?: HardwareProxy['proxyManagerName'];
    proxyParameters?: HardwareProxyParameters;
  }) => Promise<unknown>;
  getProxyParametersDefinition: (params: {
    hardwareServer: Mailbox['identifier'];
    serialNumber: Hardware['serialNumber'];
    proxyManager: HardwareProxy['proxyManagerName'];
  }) => Promise<HardwareProxyParametersDefinitionView>;
  executeHardwareAction: (params: {
    serialNumber: Hardware['serialNumber'];
    hardwareServer: Mailbox['identifier'];
    actionId: Action['id'];
    optionId?: ActionOption['id'];
  }) => Promise<ActionResult>;
  getSingleHardware: (params: {
    hardwareServer: Mailbox['identifier'];
    serialNumber: Hardware['serialNumber'];
  }) => Promise<SingleHardwareResult>;
  getHardwareActionResult: (params: {
    hardwareServer: Mailbox['identifier'];
    actionRunId: ActionRun['id'];
  }) => Promise<ActionResult>;
  listArtifacts: () => Promise<Artifact[]>;
  downloadArtifact: (artifact: Artifact) => Promise<DownloadableFile>;
  connectWebSocket: (() => WebSocket) | undefined;
};

export type SingleHardwareResult = {
  detail: HardwareDetail;
  hardware: Hardware;
};

export type TerminalCommandResult = {
  commandName: string;
  success: boolean;
  stdOut?: string;
  errOut?: string;
  exitValue?: number;
  exception?: Exception;
};

export type Action = {
  accessLevel: AclAccessLevelEnum;
  id: string;
  name: string;
  options?: Array<ActionOption>;
};

export type ActionOption = {
  id: string;
  name: string;
};

export type ActionResult = {
  run: ActionRun;
  progress: ProgressMessage;
  server: string;
  hardware?: HardwareSummary;
  completedOn?: Moment;
  commandResult?: TerminalCommandResult;
};

export type ActionRun = {
  accessLevel: AclAccessLevelEnum;
  action: Action;
  createdBy: string;
  createdOn: string;
  id: string;
  status: ActionRunProgressEnum;
  option?: ActionOption;
};

export type Exception = {
  class: string;
  details: string;
  message?: string;
};

export type ProgressMessage = {
  status: ProgressMessageEnum;
  details: string;
};

export type HardwareDetail = {
  actionRuns?: Array<ActionRun>;
};

export type AppProxy = {
  address: string;
  type: string;
};

export type ProxyManager = Mailbox;

export type Mailbox = {
  accessLevel: AclAccessLevelEnum;
  host: string;
  name: string;
  displayName: string;
  identifier: string;
  compatibilityCode: CompatibilityCodeEnum;
  compatibilityMessage: string;
};

export type Hardware = {
  accessLevel: AclAccessLevelEnum;
  product: Product;
  guard: {
    canFree: boolean;
    canConnect: boolean;
    canReserve: boolean;
  };
  actions: Array<Action>;
  interfaces: HardwareInterface;
  attributes: Object;
  name: string;
  serialNumber: string;
  server: Mailbox;
  status: HardwareStatus;
};

export type HardwareSummary = {
  attributes: Object;
  name: string;
  product: Product;
  serialNumber: string;
};

export type Product = {
  id: string;
  name: string;
  attributes: Object;
};

export type HardwarePreparation = {
  available: boolean;
  lastRunId: string;
  lastRunName?: string;
  active?: number;
  total?: number;
};

export type HardwareReservation = {
  status: ReservationStatusEnum;
  reservedBy?: string;
  reservedDate?: Moment;
  message?: string;
};

export type HardwareStatus = {
  connection: ConnectionStatusEnum;
  proxy: HardwareProxy;
  preparation: HardwarePreparation;
  reservation: HardwareReservation;
};

export type HardwareProxy = {
  active: boolean;
  proxyManagerName?: string;
  hostNetwork?: string;
  hostPrimaryAddress?: string;
  hostAllAddresses?: Array<string>;
  proxies?: Array<AppProxy>;
};

export type HardwareInterface = {
  id: string;
  name: string;
  type: EnumType;
  typeDisplay: string;
  typeDisplayShort: string;
  port?: number;
  host?: string;
};

export type Artifact = {
  path: string;
  id: string;
  name: string;
  serverName: string;
  mailboxId: string;
  uploadedOn: string;
  matchWeight: number;
  groupName?: string;
  groupVersion?: number;
};

export type MessageProgress = {
  details: string;
  status: ProgressMessageEnum;
};

export type HardwareProxyParametersDefinitionView = {
  hardwareName: string;
  productName: string;
  interfaces: InterfaceProxyParametersDefinitionView[];
};

export type InterfaceProxyParametersDefinitionView = {
  name: string;
  id: string;
  parameters: ProxyParameterDefinition[];
};

export type ProxyParameterDefinition = {
  key: string;
  type: number | string | boolean;
  label: string;
  defaultValue?: string;
  discreet?: ProxyParameterDiscreteValue[];
};

export type ProxyParameterDiscreteValue = {
  id: string;
  display: string;
};

export type HardwareProxyParameters = {
  interfaces: InterfaceProxyParameters[];
};

export type InterfaceProxyParameters = {
  id: string;
  parameters: ProxyParameter[];
};

export type ProxyParameter = {
  key: string;
  value: string;
};

export enum CompatibilityCodeEnum {
  OK = 'OK',
  APP_PM_INCOMPATIBLE = 'APP_PM_INCOMPATIBLE',
  HW_PM_INCOMPATIBLE = 'HW_PM_INCOMPATIBLE',
  HW_INCOMPATIBLE = 'HW_INCOMPATIBLE',
}

export enum ConnectionStatusEnum {
  NO_CONNECTION = 'NO_CONNECTION',
  UNKNOWN = 'UNKNOWN',
  OFFLINE = 'OFFLINE',
  PARTIALLY_ONLINE = 'PARTIALLY_ONLINE',
  ONLINE = 'ONLINE',
}

export enum ProgressMessageEnum {
  IN_PROGRESS = 'IN_PROGRESS',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  COMPLETE = 'COMPLETE',
}

export enum ActionRunProgressEnum {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  ERROR = 'ERROR',
}

export enum AclAccessLevelEnum {
  NONE = 'NONE',
  LIST = 'LIST',
  USE = 'USE',
  MAINTAIN = 'MAINTAIN',
}

export enum ReservationStatusEnum {
  FREE = 'FREE',
  RESERVED_BY_ME = 'RESERVED_BY_ME',
  RESERVED = 'RESERVED',
}

export enum AttributeTypeEnum {
  TEXT = 'TEXT',
  DATETIME = 'DATETIME',
}

export interface DownloadableFile {
  blob: Blob;
  name: string;
}
