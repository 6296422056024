import { SimpleGrid, VStack } from '@chakra-ui/layout';
import { Button, ButtonGroup } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { NavLink } from 'react-router-dom';
import { Hardware, ReservationStatusEnum } from '../../apiClient/types';
import { ConnectModal } from './ConnectModal';
import { AttributesTable } from './AttributesTable';
import { useIntl, FormattedMessage } from 'react-intl';
import { isRequiredAccessLevelForAction } from './isRequiredAccessLevelForAction';
import { useReserveHardware } from './useReserveHardware';
import { useFreeHardware } from './useFreeHardware';
import { getTableAttributes } from './getTableAttributes';

type Props = {
  hardware: Hardware;
  refresh: () => unknown;
};

export function HardwareDetailGrid({ hardware, refresh }: Props) {
  const intl = useIntl();
  const connectModalDisclosure = useDisclosure();
  const { handleReserve, isLoading: isLoadingReserve } = useReserveHardware(hardware, refresh);
  const { handleFree, isLoading: isLoadingFree } = useFreeHardware(hardware, refresh);

  return (
    <>
      <ButtonGroup variant="outline" mb={2}>
        <Button
          onClick={handleReserve}
          isDisabled={
            !hardware.guard.canReserve || !isRequiredAccessLevelForAction(hardware.accessLevel)
          }
          isLoading={isLoadingReserve}
        >
          <FormattedMessage id="hardwaredetailgrid_reserve" />
        </Button>
        <Button
          onClick={connectModalDisclosure.onOpen}
          isDisabled={
            !hardware.guard.canConnect || !isRequiredAccessLevelForAction(hardware.accessLevel)
          }
        >
          <FormattedMessage id="hardwaredetailgrid_connect" />
        </Button>
        <Button
          onClick={handleFree}
          isDisabled={
            !hardware.guard.canFree || !isRequiredAccessLevelForAction(hardware.accessLevel)
          }
          isLoading={isLoadingFree}
        >
          <FormattedMessage id="hardwaredetailgrid_free" />
        </Button>
        <Button
          as={NavLink}
          to={`/hardware/${hardware.serialNumber}&${hardware.server.identifier}`}
          variant="ghost"
        >
          <FormattedMessage id="hardwaredetailgrid_details" />
        </Button>
      </ButtonGroup>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
        <VStack align="stretch" spacing={4}>
          <AttributesTable
            attributes={getTableAttributes('product', hardware, intl)}
            caption={<FormattedMessage id="hardwaredetailgrid_product_attributes_caption" />}
          />
          {hardware.status.reservation.status !== ReservationStatusEnum.FREE && (
            <AttributesTable
              attributes={getTableAttributes('reservation', hardware, intl)}
              caption={<FormattedMessage id="hardwaredetailgrid_reservation_attributes_caption" />}
            />
          )}
        </VStack>
        <VStack align="stretch" spacing={4}>
          <AttributesTable
            attributes={getTableAttributes('hardware', hardware, intl)}
            caption={<FormattedMessage id="hardwaredetailgrid_hardware_attributes_caption" />}
          />
          {hardware.status.proxy.active && (
            <AttributesTable
              attributes={getTableAttributes('proxy', hardware, intl)}
              caption={<FormattedMessage id="hardwaredetailgrid_proxy_attributes_caption" />}
            />
          )}
        </VStack>
      </SimpleGrid>
      {connectModalDisclosure.isOpen && (
        <ConnectModal {...connectModalDisclosure} hardware={hardware} refresh={refresh} />
      )}
    </>
  );
}
