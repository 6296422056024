import { useEffect } from 'react';
import { Flex, Heading, HStack, VStack, Box, Text } from '@chakra-ui/layout';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/accordion';
import { Icon } from '@chakra-ui/icons';
import { FiBox } from 'react-icons/fi';
import { RepeatIcon } from '@chakra-ui/icons';
import { IconButton } from '@chakra-ui/button';
import { FormattedMessage } from 'react-intl';
import { Alert, AlertIcon } from '@chakra-ui/react';
import { Layout } from '../../layout/Layout';
import { HardwareDetailGrid } from './HardwareDetailGrid';
import { ConnectionStatusBadge } from './ConnectionStatusBadge';
import { ReservationStatusBadge } from './ReservationStatusBadge';
import { useLoadHardware } from './useLoadHardware';
import { ReservationStatusEnum } from '../../apiClient/types';
import { PageHeading } from '../../layout/PageHeading';

export function HardwarePage() {
  const { loadHardware, isLoading, hardwares } = useLoadHardware();

  useEffect(() => void loadHardware(), [loadHardware]);

  let noHardwareToList;
  if (hardwares && hardwares.length === 0) {
    noHardwareToList = (
      <Alert status="info">
        <AlertIcon />
        <FormattedMessage id="hardwarepage_no_hardware" />
      </Alert>
    );
  }

  return (
    <Layout>
      <HStack align="top">
        <PageHeading
          heading={<FormattedMessage id="hardwarepage_heading" />}
          description={<FormattedMessage id="hardwarepage_description" />}
        />
        <IconButton
          isRound
          isLoading={isLoading}
          variant="ghost"
          onClick={loadHardware}
          icon={<RepeatIcon />}
          aria-label="Refresh"
        />
      </HStack>
      {noHardwareToList}
      {hardwares && (
        <Accordion bg="white" allowMultiple>
          {hardwares.map((hardware) => (
            <AccordionItem key={`${hardware.server.host}-${hardware.serialNumber}`}>
              <AccordionButton>
                <Flex w="100%" justifyContent="space-between">
                  <Flex p={2}>
                    <Icon as={FiBox} mr={4} boxSize={8} color="blue.300" />
                    <Box textAlign="left">
                      <Heading as="h5" size="sm">
                        {[hardware.product.name, hardware.name].join(' – ')}
                      </Heading>
                      <Text color="gray.500">
                        {[hardware.serialNumber, hardware.server.host].join(' - ')}
                      </Text>
                    </Box>
                  </Flex>
                  <HStack>
                    {hardware.status.reservation.status !== ReservationStatusEnum.FREE && (
                      <ReservationStatusBadge hardware={hardware} />
                    )}
                    <ConnectionStatusBadge hardware={hardware} />
                  </HStack>
                </Flex>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pt={4} pb={12}>
                <HardwareDetailGrid hardware={hardware} refresh={loadHardware} />
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      )}
    </Layout>
  );
}
