import { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { SingleHardwareResult } from '../../apiClient/types';
import { useApiClient } from '../../apiClient/useApiClient';
import { useShowError } from '../../useShowError';

export function useLoadSingleHardware(hardwareServer: string, serialNumber: string) {
  const [hardwareResult, setHardwareResult] = useState<SingleHardwareResult>();
  const { getSingleHardware } = useApiClient();
  const [isLoading, setLoading] = useState(false);
  const showError = useShowError();
  const intl = useIntl();
  const loadHardware = useCallback(
    async function () {
      setLoading(true);
      try {
        setHardwareResult(
          await getSingleHardware({
            hardwareServer,
            serialNumber,
          })
        );
        setLoading(false);
      } catch (error) {
        showError(error, {
          description: intl.formatMessage({ id: 'hardwaredetailpage_load_hardware_error' }),
        });
        setLoading(false);
      }
    },
    [getSingleHardware, hardwareServer, intl, serialNumber, showError]
  );
  return {
    isLoading,
    loadHardware,
    hardware: hardwareResult?.hardware,
    hardwareDetail: hardwareResult?.detail,
  };
}
