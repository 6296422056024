import '@fontsource/lato';
import 'focus-visible/dist/focus-visible';
import { useMemo } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { theme } from './theme';
import { LocaleIntlProvider } from './i18n/LocaleIntlProvider';
import { AuthProvider } from './auth/AuthProvider';
import { ApiClientProvider } from './apiClient/ApiClientProvider';
import { LandingPage } from './pages/landing/LandingPage';
import { DashboardPage } from './pages/dashboard/DashboardPage';
import { HardwarePage } from './pages/hardware/HardwarePage';
import { DownloadsPage } from './pages/downloads/DownloadsPage';
import { LoginPage } from './pages/login/LoginPage';
import { LogoutPage } from './pages/logout/LogoutPage';
import { AuthRequired } from './auth/AuthRequired';
import { HardwareDetailPage } from './pages/hardwareDetail/HardwareDetailPage';
import { HardwareActionLogPage } from './pages/hardwareActionLog/HardwareActionLogPage';
import type { Config } from './config/types';

type Props = {
  config: Config;
};

export function App({ config }: Props) {
  const basename = useMemo(() => {
    if (config.publicUrl) {
      return new URL(config.publicUrl).pathname;
    } else {
      return '/';
    }
  }, [config.publicUrl]);
  return (
    <LocaleIntlProvider>
      <ChakraProvider theme={theme}>
        <BrowserRouter basename={basename}>
          <AuthProvider config={config}>
            <ApiClientProvider config={config}>
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route
                  path="/dashboard"
                  element={
                    <AuthRequired>
                      <DashboardPage />
                    </AuthRequired>
                  }
                />
                <Route
                  path="/hardware"
                  element={
                    <AuthRequired>
                      <HardwarePage />
                    </AuthRequired>
                  }
                />
                <Route
                  path="/download"
                  element={
                    <AuthRequired>
                      <DownloadsPage />
                    </AuthRequired>
                  }
                />
                <Route
                  path={`/hardware/:serialNumber&:hardwareServer`}
                  element={
                    <AuthRequired>
                      <HardwareDetailPage />
                    </AuthRequired>
                  }
                />
                <Route
                  path={`/hardware/:serialNumber&:hardwareServer/run/:actionRunId`}
                  element={
                    <AuthRequired>
                      <HardwareActionLogPage />
                    </AuthRequired>
                  }
                />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/logout" element={<LogoutPage />} />
              </Routes>
            </ApiClientProvider>
          </AuthProvider>
        </BrowserRouter>
      </ChakraProvider>
    </LocaleIntlProvider>
  );
}
