import { useContext } from 'react';
import { LOCALES } from './locales';
import { Select } from '@chakra-ui/react';
import { LocaleContext } from './LocaleContext';

export function LanguageAndLocaleSelector() {
  const languageLocales = [
    { name: 'English UK', code: LOCALES.ENGLISH_UK },
    { name: 'English USA', code: LOCALES.ENGLISH_US },
    { name: 'Suomi', code: LOCALES.FINNISH },
  ];

  const { locale, onChangeLocale } = useContext(LocaleContext);

  return (
    <Select
      variant="outline"
      color="white"
      borderColor="white"
      focusBorderColor="white"
      onChange={(event) => onChangeLocale(event.target.value)}
      value={locale}
      size="sm"
      iconSize="lg"
      minWidth="120px"
      maxWidth="150px"
    >
      {languageLocales.map(({ name, code }) => (
        <option key={code} value={code} className="darkGrayText">
          {name}
        </option>
      ))}
    </Select>
  );
}
