import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { Box, Container, HStack, Image, Spacer } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { LanguageAndLocaleSelector } from '../i18n/LanguageAndLocaleSelector';
import { PrimaryNavLink } from './PrimaryNavLink';
import { LoggedInUserMenu } from './LoggedInUserMenu';
import deviceVaultLogo from './devicevaultLogoR.webp';

export function Header() {
  return (
    <Box paddingX={{ base: 0, md: 8 }} paddingY={{ base: 4, md: 6 }} bg="brand.500">
      <Container maxW="container.lg" display="flex">
        <HStack spacing={8}>
          <RouterNavLink to="/">
            <Box width="220px">
              <Image src={deviceVaultLogo} />
            </Box>
          </RouterNavLink>
          <AuthenticatedTemplate>
            <PrimaryNavLink to="/dashboard">
              <FormattedMessage id="header_dashboard" />
            </PrimaryNavLink>
            <PrimaryNavLink to="/hardware">
              <FormattedMessage id="header_hardware" />
            </PrimaryNavLink>
            <PrimaryNavLink to="/download">
              <FormattedMessage id="header_downloads" />
            </PrimaryNavLink>
          </AuthenticatedTemplate>
        </HStack>
        <Spacer minWidth={8} />
        <HStack spacing={6}>
          <AuthenticatedTemplate>
            <Box>
              <LoggedInUserMenu />
            </Box>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <PrimaryNavLink to="/login">
              <FormattedMessage id="header_login" />
            </PrimaryNavLink>
          </UnauthenticatedTemplate>
          <LanguageAndLocaleSelector />
        </HStack>
      </Container>
    </Box>
  );
}
