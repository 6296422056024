import { useMemo, useState } from 'react';
import { MsalProvider } from '@azure/msal-react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { SessionContext } from './SessionContext';
import { InvalidSessionModal } from './InvalidSessionModal';
import type { Config } from '../config/types';

type Props = {
  children: React.ReactNode;
  config: Config;
};

export function AuthProvider(props: Props) {
  const [isShowingInvalidSession, setShowingInvalidSession] = useState(false);
  const msalInstance = useMemo(
    () =>
      new PublicClientApplication({
        auth: {
          authority: props.config.msalAuthority,
          clientId: props.config.msalClientId,
          redirectUri: props.config.msalRedirectUri,
          postLogoutRedirectUri: props.config.msalLogoutUri,
        },
      }),
    [props.config]
  );

  const msalLoginRequest = useMemo(
    () => ({
      scopes: props.config.msalScopes,
    }),
    [props.config.msalScopes]
  );

  function showInvalidSession() {
    setShowingInvalidSession(true);
  }

  const location = useLocation();
  const navigate = useNavigate();
  function login() {
    setShowingInvalidSession(false);
    navigate('/login', { replace: true, state: { from: location.pathname } });
  }

  function logout() {
    setShowingInvalidSession(false);
    navigate('/logout');
  }

  return (
    <SessionContext.Provider value={{ showInvalidSession, msalLoginRequest }}>
      {isShowingInvalidSession && <InvalidSessionModal login={login} logout={logout} />}
      <MsalProvider instance={msalInstance}>{props.children}</MsalProvider>
    </SessionContext.Provider>
  );
}
