import { useIntl } from 'react-intl';
import { ActionResult, AttributeTypeEnum } from '../../apiClient/types';
import { AttributesTable } from '../hardware/AttributesTable';
import { isEntryArray } from '../hardware/isEntryArray';

type Props = {
  actionResult: ActionResult;
};

export function ActionResultAttributesTable({ actionResult }: Props) {
  const intl = useIntl();

  return (
    <AttributesTable
      attributes={[
        [intl.messages.hardwareactionlogpage_actionname, actionResult.run.action.name],
        [intl.messages.hardwareactionlogpage_actionid, actionResult.run.action.id],
        [intl.messages.hardwareactionlogpage_runstatus, actionResult.run.status],
        [intl.messages.hardwareactionlogpage_runid, actionResult.run.id],
        [intl.messages.hardwareactionlogpage_startedby, actionResult.run.createdBy],
        [
          intl.messages.hardwareactionlogpage_starttime,
          actionResult.run.createdOn,
          null,
          AttributeTypeEnum.DATETIME,
        ],
        [
          intl.messages.hardwareactionlogpage_completedtime,
          actionResult.completedOn
            ? actionResult.completedOn
            : intl.messages.hardwareactionlogpage_notcompleted,
          null,
          AttributeTypeEnum.DATETIME,
        ],
      ].filter(isEntryArray)}
      caption="Run details"
    />
  );
}
