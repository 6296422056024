export class ApiError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'ApiError';
  }
}

export class InvalidSessionError extends ApiError {
  constructor(message?: string) {
    super(message);
    this.name = 'InvalidSessionError';
  }
}
