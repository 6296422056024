import { type ReactNode } from 'react';
import { Stack } from '@chakra-ui/layout';
// @ts-expect-error
import { CappedText, CappedHeading } from '@ceteio/chakra-capsize';

type Props = {
  heading: ReactNode;
  description: ReactNode;
};

export function PageHeading(props: Props) {
  return (
    <Stack spacing={4}>
      <CappedHeading as="h3" size="lg">
        {props.heading}
      </CappedHeading>
      <CappedText>{props.description}</CappedText>
    </Stack>
  );
}
