import { Badge } from '@chakra-ui/layout';
import { ConnectionStatusEnum, Hardware } from '../../apiClient/types';
import { FormattedMessage } from 'react-intl';

export function ConnectionStatusBadge(props: { hardware: Hardware }) {
  switch (props.hardware.status.connection) {
    case ConnectionStatusEnum.ONLINE:
      return (
        <Badge variant="outline" colorScheme="green">
          <FormattedMessage id="connectionstatusbadge_online" />
        </Badge>
      );
    case ConnectionStatusEnum.PARTIALLY_ONLINE:
      return (
        <Badge variant="outline" colorScheme="yellow">
          <FormattedMessage id="connectionstatusbadge_partially_online" />
        </Badge>
      );
    case ConnectionStatusEnum.OFFLINE:
      return (
        <Badge variant="outline" colorScheme="red">
          <FormattedMessage id="connectionstatusbadge_offline" />
        </Badge>
      );
    case ConnectionStatusEnum.NO_CONNECTION:
      return null;
    default:
      return (
        <Badge variant="outline" colorScheme="gray">
          <FormattedMessage id="connectionstatusbadge_unknown" />
        </Badge>
      );
  }
}
