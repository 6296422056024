import { Button, ButtonGroup, Textarea, Tooltip } from '@chakra-ui/react';
import { FiCopy } from 'react-icons/fi';
import { FormattedMessage, useIntl } from 'react-intl';

type Props = {
  value: string | number;
};

export function ClickToCopyTextArea(props: Props) {
  const intl = useIntl();

  function handleCopyToClipboard() {
    navigator.clipboard.writeText(props.value.toString());
  }

  return (
    <>
      <ButtonGroup
        style={{
          justifyContent: 'right',
          display: 'flex',
          marginRight: 4,
          marginBottom: 2,
        }}
      >
        <Tooltip
          label={<FormattedMessage id="hardwareactionlogpage_tooltip_copytoclipboard" />}
          fontSize="md"
        >
          <Button
            leftIcon={<FiCopy />}
            variant="outline"
            color="blue.300"
            onClick={handleCopyToClipboard}
          >
            <FormattedMessage id="hardwareactionlogpage_copy" />
          </Button>
        </Tooltip>
      </ButtonGroup>
      <Textarea
        placeholder={intl.formatMessage({
          id: 'hardwareactionlogpage_textarea_placeholder_helper',
        })}
        isReadOnly
        height={1000}
        resize="none"
      >
        {props.value.toString()}
      </Textarea>
    </>
  );
}
