import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Circle,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

export function InstructionsAccordion() {
  return (
    <Accordion bg="white" allowMultiple>
      <AccordionItem border={0}>
        <AccordionButton textAlign="left">
          <Box py={6} px={4}>
            <Heading as="h4" size="md" mb={2}>
              <FormattedMessage id="dashboardpage_remote_access_heading" />
            </Heading>
            <Text>
              <FormattedMessage id="dashboardpage_remote_access_description" />
            </Text>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <Box px={4}>
            <Flex mb={4}>
              <Circle size="40px" bg="brand.500" color="white" mr={6}>
                1
              </Circle>
              <Box>
                <Heading as="h5" size="sm">
                  <FormattedMessage id="dashboardpage_remote_access_step1_heading" />
                </Heading>
                <Text>
                  <FormattedMessage id="dashboardpage_remote_access_step1_description" />
                </Text>
              </Box>
            </Flex>

            <Flex mb={4}>
              <Circle size="40px" bg="brand.500" color="white" mr={6}>
                2
              </Circle>
              <Box>
                <Heading as="h5" size="sm">
                  <FormattedMessage id="dashboardpage_remote_access_step2_heading" />
                </Heading>
                <Text>
                  <FormattedMessage id="dashboardpage_remote_access_step2_description" />
                </Text>
              </Box>
            </Flex>

            <Flex mb={4}>
              <Circle size="40px" bg="brand.500" color="white" mr={6}>
                3
              </Circle>
              <Box>
                <Heading as="h5" size="sm">
                  <FormattedMessage id="dashboardpage_remote_access_step3_heading" />
                </Heading>
                <Text>
                  <FormattedMessage id="dashboardpage_remote_access_step3_description" />
                </Text>
              </Box>
            </Flex>

            <Flex mb={4}>
              <Circle size="40px" bg="brand.500" color="white" mr={6}>
                4
              </Circle>
              <Box>
                <Heading as="h5" size="sm">
                  <FormattedMessage id="dashboardpage_remote_access_step4_heading" />
                </Heading>
                <Text>
                  <FormattedMessage id="dashboardpage_remote_access_step4_description" />
                </Text>
              </Box>
            </Flex>

            <Flex mb={4}>
              <Circle size="40px" bg="brand.500" color="white" mr={6}>
                5
              </Circle>
              <Box>
                <Heading as="h5" size="sm">
                  <FormattedMessage id="dashboardpage_remote_access_step5_heading" />
                </Heading>
                <Text>
                  <FormattedMessage id="dashboardpage_remote_access_step5_description" />
                </Text>
              </Box>
            </Flex>
          </Box>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
