import { Heading, HStack, VStack, Box, Flex } from '@chakra-ui/layout';
import { FiClipboard } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';
import { Tabs, TabList, TabPanels, Tab, TabPanel, SimpleGrid } from '@chakra-ui/react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/accordion';
import { Icon } from '@chakra-ui/icons';
import { HardwareActionProgressBadge } from '../hardware/HardwareActionProgressBadge';
import { ActionResult } from '../../apiClient/types';
import { ClickToCopyTextArea } from './ClickToCopyTextArea';
import { ActionResultAttributesTable } from './ActionResultAttributesTable';
import { PageHeading } from '../../layout/PageHeading';

type Props = {
  actionResult: ActionResult;
};

export function HardwareActionLogContent({ actionResult }: Props) {
  return (
    <>
      <PageHeading
        heading={<FormattedMessage id="hardwareactionlogpage_heading" />}
        description={<FormattedMessage id="hardwareactionlogpage_subtitle" />}
      />
      <Box background={'white'}>
        <Box p={2}>
          <HStack>
            <HardwareActionProgressBadge result={actionResult} fontSize={14} />
            <Heading
              fontSize={20}
            >{`${actionResult.run.action.name} - ${actionResult.run.id}`}</Heading>
          </HStack>
        </Box>

        <Tabs>
          <TabList>
            <Tab>
              <FormattedMessage id="hardwareactionlogpage_tab_summary" />
            </Tab>
            <Tab>
              <FormattedMessage id="hardwareactionlogpage_tab_logs" />
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                <VStack spacing={4}>
                  <Box>
                    <ActionResultAttributesTable actionResult={actionResult} />
                  </Box>
                </VStack>
              </SimpleGrid>
            </TabPanel>
            <TabPanel>
              <Accordion allowToggle>
                <AccordionItem>
                  <AccordionButton>
                    <Flex w="100%" justifyContent="space-between">
                      <Flex p={2}>
                        <Icon as={FiClipboard} mr={4} boxSize={8} color="blue.300"></Icon>
                        <Box textAlign="left">
                          <Heading as="h5" size="sm">
                            <FormattedMessage id="hardwareactionlogpage_progress" />
                          </Heading>
                        </Box>
                      </Flex>
                    </Flex>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    {actionResult.progress && (
                      <ClickToCopyTextArea value={actionResult.progress.details} />
                    )}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
              {actionResult.commandResult && (
                <Accordion allowToggle>
                  <AccordionItem>
                    <AccordionButton>
                      <Flex w="100%" justifyContent="space-between">
                        <Flex p={2}>
                          <Icon as={FiClipboard} mr={4} boxSize={8} color="blue.300"></Icon>
                          <Box textAlign="left">
                            <Heading as="h5" size="sm">
                              <FormattedMessage id="hardwareactionlogpage_results" />
                            </Heading>
                          </Box>
                        </Flex>
                      </Flex>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                      {actionResult.commandResult && (
                        <ClickToCopyTextArea
                          value={
                            actionResult.commandResult.errOut ??
                            actionResult.commandResult.stdOut ??
                            actionResult.commandResult.exitValue ??
                            ''
                          }
                        />
                      )}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
}
