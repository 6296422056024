import { useCallback, useContext, useMemo } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-common';
import { ApiClientContext } from './ApiClientContext';
import { InvalidSessionError, ApiError } from './errors';
import { DownloadableFile, Hardware, ApiClient } from './types';
import type { Config } from '../config/types';
import { SessionContext } from '../auth/SessionContext';

function buildQueryString(params: { [key: string]: string | number | boolean }) {
  let str = [];
  for (let param in params)
    if (params.hasOwnProperty(param)) {
      const value = params[param];
      str.push(
        value !== null && typeof value === 'object'
          ? param + '=' + encodeURIComponent(JSON.stringify(value))
          : param + '=' + encodeURIComponent(value)
      );
    }
  return str.join('&');
}

function loggedThrow(error: Error, ...additionalLogs: unknown[]): never {
  console.warn(error, ...additionalLogs);
  throw error;
}

function buildUrl(apiBaseUrl: string, path: string, params: {} = {}) {
  return [apiBaseUrl, path, '?' + buildQueryString(params)].join('');
}

type Props = {
  children: React.ReactNode;
  config: Config;
};

export function ApiClientProvider(props: Props) {
  const { msalLoginRequest } = useContext(SessionContext);
  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];

  const getAccessToken = useCallback(
    async function getAccessToken() {
      return await instance.acquireTokenSilent({ ...msalLoginRequest, account }).catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          loggedThrow(new InvalidSessionError('Interaction is required to acquire a token.'));
        }
        throw error;
      });
    },
    [instance, msalLoginRequest, account]
  );

  const connectWebSocket = useMemo<ApiClient['connectWebSocket']>(() => {
    const { apiWebsocketUrl } = props.config;
    if (apiWebsocketUrl) {
      return function connectWebSocket() {
        const webSocket = new WebSocket(apiWebsocketUrl);
        webSocket.onopen = async () => {
          if (webSocket.readyState === 1) {
            const { accessToken } = await getAccessToken();
            webSocket.send(`Token ${accessToken}`);
          }
        };
        return webSocket;
      };
    } else {
      return undefined;
    }
  }, [props.config, getAccessToken]);

  const request = useCallback(
    async function request(path: string, params: {} = {}) {
      if (!account) {
        loggedThrow(new InvalidSessionError('No account available.'));
      }

      const headers = new Headers();
      const accessToken = (await getAccessToken()).accessToken;
      headers.append('Authorization', `Bearer ${accessToken}`);
      const encodedURL = buildUrl(props.config.apiBaseUrl, path, params);
      const res = await fetch(encodedURL, { headers });
      if (res.ok) {
        return res;
      }
      const text = await res.text();
      if (text.match('Invalid Session')) {
        loggedThrow(new InvalidSessionError('API reports that the session is invalid.'));
      }

      loggedThrow(new ApiError('Unknown API error.'), { responseText: text });
    },
    [getAccessToken, account, props.config.apiBaseUrl]
  );

  const jsonRequest = useCallback(
    async function jsonRequest(path: string, params: {} = {}) {
      const res = await request(path, params);
      if (res.headers.get('Content-Type')?.match('json')) {
        return res.json();
      }

      loggedThrow(new ApiError('Invalid response.'), {
        'Content-Type': res.headers.get('Content-Type'),
      });
    },
    [request]
  );

  const downloadFile = useCallback(
    async function downloadFile(path: string, params: {} = {}): Promise<DownloadableFile> {
      const res = await request(path, params);

      // attachment;filename="Proxy Download DHBHPPU.zip"
      const contentDisposition = res.headers.get('Content-Disposition') ?? '';
      const fileName = contentDisposition
        ?.split(';')[1]
        ?.split('filename')[1]
        ?.split('=')[1]
        ?.trim()
        .replaceAll('"', '');

      return { blob: await res.blob(), name: fileName };
    },
    [request]
  );

  const freeHardware = useCallback<ApiClient['freeHardware']>(
    function freeHardware({ hardwareServer, serialNumber }) {
      return jsonRequest('/hardware/free', {
        hardwareServer: hardwareServer,
        serialNumber: serialNumber,
      });
    },
    [jsonRequest]
  );

  const listHardware = useCallback<ApiClient['listHardware']>(
    async function listHardware() {
      let hardwares: Hardware[] = (await jsonRequest('/hardware')).hardware;
      return hardwares;
    },
    [jsonRequest]
  );

  const listProxyManagers = useCallback<ApiClient['listProxyManagers']>(
    async function listProxyManagers() {
      return (await jsonRequest('/proxy-managers')).proxyManagers;
    },
    [jsonRequest]
  );

  const reserveHardware = useCallback<ApiClient['reserveHardware']>(
    function reserveHardware({ hardwareServer, serialNumber }) {
      return jsonRequest('/hardware/select', {
        hardwareServer: hardwareServer,
        serialNumber: serialNumber,
      });
    },
    [jsonRequest]
  );

  const selectHardware = useCallback<ApiClient['selectHardware']>(
    function selectHardware({ hardwareServer, serialNumber, proxyManager, proxyParameters }) {
      if (proxyManager) {
        return jsonRequest('/hardware/select', {
          proxyManager,
          hardwareServer,
          serialNumber,
          proxyParameters,
        });
      }
      return jsonRequest('/hardware/select', {
        hardwareServer: hardwareServer,
        serialNumber: serialNumber,
      });
    },
    [jsonRequest]
  );

  const getProxyParametersDefinition = useCallback<ApiClient['getProxyParametersDefinition']>(
    async function getProxyParametersDefinition({ hardwareServer, serialNumber, proxyManager }) {
      return jsonRequest('/proxy/parameters', {
        proxyManager,
        hardwareServer,
        serialNumber,
      }).then(({ result }) => result);
    },
    [jsonRequest]
  );

  const getSingleHardware = useCallback<ApiClient['getSingleHardware']>(
    function getSingleHardware({ hardwareServer, serialNumber }) {
      return jsonRequest('/hardware/get', {
        hardwareServer,
        serialNumber,
      });
    },
    [jsonRequest]
  );

  const executeHardwareAction = useCallback<ApiClient['executeHardwareAction']>(
    function executeHardwareAction({ hardwareServer, serialNumber, actionId, optionId }) {
      if (optionId) {
        return jsonRequest('/hardware/action', {
          serialNumber,
          hardwareServer,
          actionId,
          optionId,
        }).then(({ result }) => result);
      }
      return jsonRequest('/hardware/action', {
        actionId,
        hardwareServer,
        serialNumber,
      });
    },
    [jsonRequest]
  );

  const getHardwareActionResult = useCallback<ApiClient['getHardwareActionResult']>(
    function getHardwareActionResult({ hardwareServer, actionRunId }) {
      return jsonRequest('/hardware/action/result', {
        hardwareServer,
        actionRunId,
      }).then(({ result }) => result);
    },
    [jsonRequest]
  );

  const downloadProxyManager = useCallback<ApiClient['downloadProxyManager']>(
    function downloadProxyManager() {
      return downloadFile('/download/proxy-manager');
    },
    [downloadFile]
  );

  const listArtifacts = useCallback<ApiClient['listArtifacts']>(
    async function listArtifacts() {
      return (
        await jsonRequest('/artifact/list/distributable', {
          versionLimit: 1, // TODO paging
          matchLimit: 20, // TODO paging
        })
      ).artifacts;
    },
    [jsonRequest]
  );

  const downloadArtifact = useCallback<ApiClient['downloadArtifact']>(
    function downloadArtifact(artifact) {
      return downloadFile('/artifact/download', {
        artifactId: artifact.id,
        fortKnoxServer: artifact.mailboxId,
      });
    },
    [downloadFile]
  );

  return (
    <ApiClientContext.Provider
      value={{
        freeHardware,
        listProxyManagers,
        listHardware,
        reserveHardware,
        selectHardware,
        executeHardwareAction,
        getSingleHardware,
        getHardwareActionResult,
        downloadProxyManager,
        listArtifacts,
        downloadArtifact,
        connectWebSocket,
        getProxyParametersDefinition,
      }}
    >
      {props.children}
    </ApiClientContext.Provider>
  );
}
