import { useToast } from '@chakra-ui/toast';
import { useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { ApiError, InvalidSessionError } from './apiClient/errors';
import { SessionContext } from './auth/SessionContext';

export function useShowError() {
  const toast = useToast();
  const intl = useIntl();
  const { showInvalidSession } = useContext(SessionContext);

  return useCallback(
    function showError(error: unknown, options: { description?: string } = {}) {
      if (error instanceof InvalidSessionError) {
        showInvalidSession();
        return;
      }

      let description;
      if (options.description) {
        description = options.description;
      } else if (error instanceof Error) {
        description = error.message;
      }

      if (error instanceof ApiError) {
        toast({
          title: intl.formatMessage({ id: 'useshowerror_request_failed' }),
          description,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        return;
      }

      console.error('Unexpected error', error);
      toast({
        title: intl.formatMessage({ id: 'useshowerror_error' }),
        description,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    },
    [showInvalidSession, toast, intl]
  );
}
