import { useContext } from 'react';
import { Badge } from '@chakra-ui/layout';
import { NavLink } from 'react-router-dom';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Link,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { ActionRun, ActionRunProgressEnum, Hardware } from '../../apiClient/types';
import { LocaleContext } from '../../i18n/LocaleContext';

type Props = {
  hardware: Hardware;
  actionRuns?: ActionRun[];
};

const getActionStatusBadge = (run: ActionRun) => {
  switch (run.status) {
    case ActionRunProgressEnum.SUCCESS:
      return (
        <Badge variant="outline" colorScheme="green">
          <FormattedMessage id="hardwareactionprogressbadge_success" />
        </Badge>
      );
    case ActionRunProgressEnum.IN_PROGRESS:
      return (
        <Badge variant="outline" colorScheme="yellow">
          <FormattedMessage id="hardwareactionprogressbadge_inprogress" />
        </Badge>
      );
    case ActionRunProgressEnum.FAILED:
      return (
        <Badge variant="outline" colorScheme="red">
          <FormattedMessage id="hardwareactionprogressbadge_error" />
        </Badge>
      );
    case ActionRunProgressEnum.ERROR:
      return (
        <Badge variant="outline" colorScheme="gray">
          <FormattedMessage id="hardwareactionprogressbadge_error" />
        </Badge>
      );
    default:
      return (
        <Badge variant="outline" colorScheme="gray">
          <FormattedMessage id="hardwareactionprogressbadge_unknown" />
        </Badge>
      );
  }
};

export function HardwareActionRunTable({ hardware, actionRuns }: Props) {
  const { formatDate } = useContext(LocaleContext);

  if (!actionRuns || actionRuns.length === 0) {
    return (
      <Alert status="info">
        <AlertIcon />
        <FormattedMessage id="hardwaredetailpage_no_action_history" />
      </Alert>
    );
  }

  return (
    <Table variant="simple">
      <TableCaption>
        <FormattedMessage id="hardwareactionruntable_caption" />
      </TableCaption>
      <Thead>
        <Tr>
          <Th>
            <FormattedMessage id="hardwareactionruntable_id" />
          </Th>
          <Th>
            <FormattedMessage id="hardwareactionruntable_status" />
          </Th>
          <Th>
            <FormattedMessage id="hardwareactionruntable_action" />
          </Th>
          <Th>
            <FormattedMessage id="hardwareactionruntable_actionid" />
          </Th>
          <Th>
            <FormattedMessage id="hardwareactionruntable_startedBy" />
          </Th>
          <Th>
            <FormattedMessage id="hardwareactionruntable_date" />
          </Th>
        </Tr>
      </Thead>
      {actionRuns.map((run: ActionRun) => (
        <Tbody key={run.id}>
          <Tr>
            <Td textColor={'blue.300'}>
              <Link
                as={NavLink}
                to={`${hardware.serialNumber}&${hardware.server.identifier}/run/${run.id}`}
              >
                {run.id}
              </Link>
            </Td>
            <Td>{getActionStatusBadge(run)}</Td>
            <Td>{run.action.name}</Td>
            <Td>{run.action.id}</Td>
            <Td>{run.createdBy}</Td>
            <Td>{formatDate(run.createdOn)}</Td>
          </Tr>
        </Tbody>
      ))}
    </Table>
  );
}
