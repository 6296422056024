import * as React from 'react';
import { ApiClient } from './types';

export const ApiClientContext = React.createContext<ApiClient>({
  freeHardware: Promise.reject,
  listProxyManagers: Promise.reject,
  listHardware: Promise.reject,
  reserveHardware: Promise.reject,
  selectHardware: Promise.reject,
  executeHardwareAction: Promise.reject,
  getSingleHardware: Promise.reject,
  getHardwareActionResult: Promise.reject,
  listArtifacts: Promise.reject,
  downloadProxyManager: Promise.reject,
  downloadArtifact: Promise.reject,
  connectWebSocket: undefined,
  getProxyParametersDefinition: Promise.reject,
});
