import { useMsal, useAccount } from '@azure/msal-react';
import {
  Box,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { ChevronDownIcon, Icon } from '@chakra-ui/icons';
import { BiUserCircle } from 'react-icons/bi';
import { tz } from 'moment-timezone';
import { ReactNode } from 'react';

function MenuInfoItem(props: { title: ReactNode; children: ReactNode }) {
  return (
    <Stack py={2} px="12.8px" color="gray.500">
      <Heading size="xs">{props.title}</Heading>
      {props.children}
    </Stack>
  );
}

export function LoggedInUserMenu() {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  return (
    <Menu autoSelect={false} placement="bottom-end">
      <MenuButton color="white" display="block">
        <HStack spacing={2}>
          <Icon as={BiUserCircle} boxSize={8} />
          <ChevronDownIcon fontSize="lg" boxSize="1em" />
        </HStack>
      </MenuButton>
      <MenuList>
        <MenuInfoItem title={<FormattedMessage id="header_logged_in_as" />}>
          <HStack alignItems="start">
            <Icon as={BiUserCircle} boxSize="24px" />
            <Box>
              <Text fontWeight="bold">{account?.name}</Text>
              <Text>{account?.username}</Text>
            </Box>
          </HStack>
        </MenuInfoItem>
        <MenuInfoItem title={<FormattedMessage id="header_timezone" />}>
          <Text>{tz.guess()}</Text>
        </MenuInfoItem>
        <MenuDivider />
        <MenuItem>
          <RouterNavLink style={{ display: 'block', width: '100%' }} to="/logout">
            <FormattedMessage id="header_logout" />
          </RouterNavLink>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
