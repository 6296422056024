import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Text, Box } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { VStack } from '@chakra-ui/react';
import { ActionResult, Hardware } from '../../apiClient/types';
import { HardwareActionProgressBadge } from '../hardware/HardwareActionProgressBadge';

export function HardwareActionModal(props: {
  result: ActionResult;
  hardware: Hardware;
  onClose: () => unknown;
  refresh: () => unknown;
}) {
  function handleClose() {
    props.onClose();
    props.refresh();
  }

  return (
    <Modal isOpen onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{props.result.run.action.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            <HardwareActionProgressBadge result={props.result} />
            <Box>
              <FormattedMessage id="hardwareactionmodal_description" />
            </Box>
            <Box mt={2} textColor={'blue.300'}>
              <NavLink
                to={`/hardware/${props.hardware.serialNumber}&${props.hardware.server.identifier}/run/${props.result.run.id}`}
              >
                <Text>{props.result.run.id}</Text>
              </NavLink>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" variant="solid" onClick={handleClose}>
            <FormattedMessage id="commons_close" />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
