import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useApiClient } from '../../apiClient/useApiClient';
import { Hardware } from '../../apiClient/types';
import { useShowError } from '../../useShowError';

export function useFreeHardware(hardware: Hardware | undefined, onSuccess: () => unknown) {
  const apiClient = useApiClient();
  const showError = useShowError();
  const [isLoading, setLoading] = useState(false);
  const intl = useIntl();
  async function handleFree() {
    if (!hardware) return;
    setLoading(true);
    try {
      await apiClient.freeHardware({
        hardwareServer: hardware.server.identifier,
        serialNumber: hardware.serialNumber,
      });
      onSuccess();
    } catch (error) {
      showError(error, {
        description: intl.formatMessage({ id: 'hardwaredetailgrid_free_hardware_error' }),
      });
    }
    setLoading(false);
  }
  return { isLoading, handleFree };
}
