import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useApiClient } from '../../apiClient/useApiClient';
import type { Hardware } from '../../apiClient/types';
import { useShowError } from '../../useShowError';

export function useReserveHardware(hardware: Hardware | undefined, onSuccess: () => unknown) {
  const { reserveHardware } = useApiClient();
  const showError = useShowError();
  const intl = useIntl();
  const [isLoading, setLoading] = useState(false);
  async function handleReserve() {
    if (!hardware) {
      return;
    }
    setLoading(true);
    try {
      await reserveHardware({
        hardwareServer: hardware.server.identifier,
        serialNumber: hardware.serialNumber,
      });
      onSuccess();
    } catch (error) {
      showError(error, {
        description: intl.formatMessage({ id: 'hardwaredetailgrid_reserve_hardware_error' }),
      });
    }
    setLoading(false);
  }
  return { isLoading, handleReserve };
}
