import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Alert, AlertIcon, Spinner, Text } from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';
import { IconButton } from '@chakra-ui/button';
import { Layout } from '../../layout/Layout';
import { useLoadSingleHardware } from './useLoadSingleHardware';
import { HardwareDetailContent } from './HardwareDetailContent';
import { useEffect } from 'react';

export function HardwareDetailPage() {
  const { hardwareServer, serialNumber } = useParams();
  if (!hardwareServer || !serialNumber) {
    throw new Error('Component can only be used as a route element with the required params.');
  }

  const {
    loadHardware,
    isLoading: isLoadingHardware,
    hardware,
    hardwareDetail,
  } = useLoadSingleHardware(hardwareServer, serialNumber);

  const loader = (
    <>
      <Text>
        <FormattedMessage id="hardwaredetailpage_loading_hardware" />
      </Text>
      <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="brand.500" size="xl" />
    </>
  );
  const noHardwareAlert = (
    <>
      <Alert status="info">
        <AlertIcon />
        <FormattedMessage id="hardwarepage_no_hardware" />
      </Alert>
      <IconButton
        isRound
        isLoading={isLoadingHardware}
        variant="ghost"
        onClick={loadHardware}
        icon={<RepeatIcon />}
        aria-label="Refresh"
      />
    </>
  );

  useEffect(() => {
    loadHardware();
  }, [loadHardware]);

  let content;
  if (isLoadingHardware) {
    content = loader;
  } else if (hardware && hardwareDetail) {
    content = (
      <HardwareDetailContent
        hardware={hardware}
        hardwareDetail={hardwareDetail}
        refresh={loadHardware}
      />
    );
  } else {
    content = noHardwareAlert;
  }

  return <Layout>{content}</Layout>;
}
