import Ajv from 'ajv';
import configSchema from './configSchema.json';
import type { FoxWebAppRuntimeLoadedConfig } from './configSchema';
import type { Config } from './types';

export async function loadConfig(): Promise<Config> {
  const res = await fetch('/config.json');

  if (!res.ok) throw new Error('Failed to load config: Response not OK.');

  const loadedConfig = await res.json();

  const validate = new Ajv().compile<FoxWebAppRuntimeLoadedConfig>(configSchema);
  if (!validate(loadedConfig)) throw new Error('Failed to load config: Invalid config.');

  return applyDefaults(applyIgnored(loadedConfig));
}

function applyIgnored(loadedConfig: FoxWebAppRuntimeLoadedConfig): FoxWebAppRuntimeLoadedConfig {
  const { $schema, ...nonIgnoredConfig } = loadedConfig;
  return nonIgnoredConfig;
}

function applyDefaults(loadedConfig: FoxWebAppRuntimeLoadedConfig): Config {
  const { msalScopes, msalAuthority, msalRedirectUri, msalLogoutUri, ...requiredConfig } =
    loadedConfig;
  return {
    ...requiredConfig,
    msalScopes: msalScopes ?? ['api://8b3528f7-9175-4ff2-9f86-5f4e7dd03958/.default'],
    msalAuthority: msalAuthority ?? 'https://login.microsoftonline.com/common/',
    msalRedirectUri: msalRedirectUri ?? `${requiredConfig.publicUrl}/`,
    msalLogoutUri: msalLogoutUri ?? `${requiredConfig.publicUrl}/logout`,
  };
}
