import { Button } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useIsAuthenticated } from '@azure/msal-react';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Layout } from '../../layout/Layout';
import { PageHeading } from '../../layout/PageHeading';

export function LandingPage() {
  const isLoggedIn = useIsAuthenticated();

  if (isLoggedIn) {
    return (
      <Layout>
        <Navigate replace to="/dashboard" />
      </Layout>
    );
  }

  return (
    <Layout>
      <PageHeading
        heading={<FormattedMessage id="landingpage_heading" />}
        description={<FormattedMessage id="landingpage_description" />}
      />
      <Button
        as={RouterLink}
        to="/login"
        variant="solid"
        rightIcon={<ArrowForwardIcon />}
        alignSelf="start"
      >
        <FormattedMessage id="landingpage_login_with_azure_ad" />
      </Button>
    </Layout>
  );
}
