import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert, AlertIcon } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { RepeatIcon } from '@chakra-ui/icons';
import { IconButton } from '@chakra-ui/button';
import { Layout } from '../../layout/Layout';
import { useLoadHardwareActionLog } from './useLoadHardwareActionLog';
import { HardwareActionLogContent } from './HardwareActionLogContent';

export function HardwareActionLogPage() {
  const { hardwareServer, actionRunId } = useParams();
  if (!hardwareServer || !actionRunId) {
    throw new Error('Component can only be used as a route element with the required params.');
  }
  const { loadLog, isLoading, actionResult } = useLoadHardwareActionLog(
    hardwareServer,
    actionRunId
  );

  useEffect(() => {
    void loadLog();
  }, [loadLog]);

  let content;
  if (isLoading) {
    content = (
      <>
        <Alert status="info">
          <AlertIcon />
          <FormattedMessage id="hardwareactionlogpage_loading" />
        </Alert>
        <IconButton
          isRound
          isLoading={isLoading}
          variant="ghost"
          onClick={loadLog}
          icon={<RepeatIcon />}
          aria-label="Refresh"
        />
      </>
    );
  } else if (actionResult) {
    content = <HardwareActionLogContent actionResult={actionResult} />;
  } else {
    content = null;
  }

  return <Layout>{content}</Layout>;
}
