import { useCallback, useContext, useEffect, useState } from 'react';
import {
  Button,
  Stack,
  HStack,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
} from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { saveAs } from 'file-saver';
import { DownloadIcon, RepeatIcon } from '@chakra-ui/icons';
import { Layout } from '../../layout/Layout';
import { useApiClient } from '../../apiClient/useApiClient';
import { useShowError } from '../../useShowError';
import { PageHeading } from '../../layout/PageHeading';
import { LocaleContext } from '../../i18n/LocaleContext';
import { type Artifact } from '../../apiClient/types';

export function DownloadsPage() {
  const showError = useShowError();
  const { downloadProxyManager } = useApiClient();
  const intl = useIntl();
  const { formatDate } = useContext(LocaleContext);
  const { listArtifacts, downloadArtifact } = useApiClient();

  const [isLoadingArtifacts, setLoadingArtifacts] = useState(false);
  const [artifacts, setArtifacts] = useState<Artifact[]>();
  const loadArtifacts = useCallback(
    async function loadArtifacts() {
      setLoadingArtifacts(true);
      try {
        setArtifacts(await listArtifacts());
      } catch (error) {
        showError(error, {
          description: intl.formatMessage({ id: 'downloadspage_list_error' }),
        });
      }
      setLoadingArtifacts(false);
    },
    [listArtifacts, showError, intl]
  );

  // Q: Is 'void' necessary here?
  useEffect(() => void loadArtifacts(), [loadArtifacts]);

  const [downloadingArtifactIds, setDownloadingArtifactIds] = useState<Set<string>>(new Set());
  const downloadFile = async (artifact: Artifact) => {
    try {
      setDownloadingArtifactIds((prev) => {
        const next = new Set(prev);
        return next.add(artifact.id);
      });
      const file = await downloadArtifact(artifact);
      saveAs(file.blob, file.name);
    } catch (error) {
      showError(error, {
        description: intl.formatMessage({ id: 'downloadspage_download_error' }),
      });
    } finally {
      setDownloadingArtifactIds((prev) => {
        const next = new Set(prev);
        next.delete(artifact.id);
        return next;
      });
    }
  };

  return (
    <Layout>
      <HStack align="top">
        <PageHeading
          heading={<FormattedMessage id="downloadspage_heading" />}
          description={<FormattedMessage id="downloadspage_description" />}
        />
        <IconButton
          isRound
          isLoading={isLoadingArtifacts}
          variant="ghost"
          onClick={loadArtifacts}
          icon={<RepeatIcon />}
          aria-label="Refresh"
        />
      </HStack>
      {artifacts && (
        <Table colorScheme="gray" bg="white">
          <Thead>
            <Tr>
              <Th>Artifact</Th>
              <Th>Details</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {artifacts.map((artifact) => (
              <Tr key={artifact.id}>
                <Td>{artifact.name}</Td>
                <Td>
                  <Stack>
                    <Text>{artifact.serverName}</Text>
                    <Text>
                      {artifact.path}/{artifact.id}
                    </Text>
                    <Text>Uploaded: {formatDate(artifact.uploadedOn)}</Text>
                  </Stack>
                </Td>
                <Td textAlign="end">
                  <Button
                    onClick={downloadFile.bind(null, artifact)}
                    variant="ghost"
                    leftIcon={<DownloadIcon />}
                    marginY="-1.5"
                    isLoading={downloadingArtifactIds.has(artifact.id)}
                  >
                    Download
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Layout>
  );
}
