import { HStack, VStack, Box } from '@chakra-ui/layout';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  SimpleGrid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { Button, ButtonGroup } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { HardwareActionContainer } from './HardwareActionContainer';
import { ReservationStatusBadge } from '../hardware/ReservationStatusBadge';
import { HardwareActionModal } from './HardwareActionModal';
import { AttributesTable } from '../hardware/AttributesTable';
import { Action, Hardware, HardwareDetail, ReservationStatusEnum } from '../../apiClient/types';
import { ConnectionStatusBadge } from '../hardware/ConnectionStatusBadge';
import { ConnectModal } from '../hardware/ConnectModal';
import { useReserveHardware } from '../hardware/useReserveHardware';
import { useHardwareAction } from './useHardwareAction';
import { useFreeHardware } from '../hardware/useFreeHardware';
import { HardwareActionRunTable } from './HardwareActionRunTable';
import { isRequiredAccessLevelForAction } from '../hardware/isRequiredAccessLevelForAction';
import { getTableAttributes } from '../hardware/getTableAttributes';
import { PageHeading } from '../../layout/PageHeading';

type Props = {
  hardware: Hardware;
  hardwareDetail: HardwareDetail;
  refresh: () => Promise<void>;
};

export function HardwareDetailContent({ hardware, hardwareDetail, refresh }: Props) {
  const intl = useIntl();
  const connectModalDisclosure = useDisclosure();
  const { handleReserve, isLoading: isLoadingReserve } = useReserveHardware(hardware, refresh);
  const { handleFree, isLoading: isLoadingFree } = useFreeHardware(hardware, refresh);
  const {
    handleStartAction,
    isLoading: isLoadingAction,
    actionResult,
    handleClearActionResult,
  } = useHardwareAction(hardware);

  return (
    <>
      <PageHeading
        heading={<FormattedMessage id="hardwaredetailpage_heading" />}
        description={<FormattedMessage id="hardwaredetailpage_subtitle" />}
      />
      <HStack>
        {hardware.status.reservation.status !== ReservationStatusEnum.FREE && (
          <ReservationStatusBadge hardware={hardware} />
        )}
        <ConnectionStatusBadge hardware={hardware} />
      </HStack>
      <Box background={'white'}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
          <VStack align="stretch">
            <Box p={2}>
              <AttributesTable
                attributes={getTableAttributes('product', hardware, intl)}
                caption={<FormattedMessage id="hardwaredetailgrid_product_attributes_caption" />}
              />
            </Box>
            <Box p={2}>
              <AttributesTable
                attributes={getTableAttributes('hardware', hardware, intl)}
                caption={<FormattedMessage id="hardwaredetailgrid_hardware_attributes_caption" />}
              />
            </Box>
          </VStack>
        </SimpleGrid>
        <Tabs>
          <TabList>
            <Tab>
              <FormattedMessage id="hardwaredetailpage_tab_actions" />
            </Tab>
            <Tab>
              <FormattedMessage id="hardwaredetailpage_tab_action_history" />
            </Tab>
            <Tab>
              <FormattedMessage id="hardwaredetailpage_tab_reservation" />
            </Tab>
            <Tab>
              <FormattedMessage id="hardwaredetailpage_tab_proxy" />
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              {hardware.actions ? (
                <>
                  {hardware.guard.canReserve && (
                    <Alert status="info">
                      <AlertIcon />
                      <FormattedMessage id="hardwaredetailpage_no_current_reservation" />
                    </Alert>
                  )}
                  {hardware.actions.map((action: Action) => (
                    <HardwareActionContainer
                      key={action.id}
                      hardwareAction={action}
                      isLoading={isLoadingAction}
                      onStartAction={handleStartAction}
                      isReserved={hardware.guard.canReserve}
                    />
                  ))}
                </>
              ) : (
                <Alert status="info">
                  <AlertIcon />
                  <FormattedMessage id="hardwaredetailpage_no_actions" />
                </Alert>
              )}
            </TabPanel>

            <TabPanel>
              <HardwareActionRunTable hardware={hardware} actionRuns={hardwareDetail.actionRuns} />
            </TabPanel>

            <TabPanel>
              <ButtonGroup variant="outline" marginBottom={2}>
                <Button
                  onClick={handleReserve}
                  isDisabled={
                    !hardware.guard.canReserve ||
                    !isRequiredAccessLevelForAction(hardware.accessLevel)
                  }
                  isLoading={isLoadingReserve}
                >
                  <FormattedMessage id="hardwaredetailgrid_reserve" />
                </Button>
                <Button
                  onClick={handleFree}
                  isDisabled={
                    !hardware.guard.canFree || !isRequiredAccessLevelForAction(hardware.accessLevel)
                  }
                  isLoading={isLoadingFree}
                >
                  <FormattedMessage id="hardwaredetailgrid_free" />
                </Button>
              </ButtonGroup>
              {hardware.status.reservation.status !== ReservationStatusEnum.FREE ? (
                <AttributesTable
                  attributes={getTableAttributes('reservation', hardware, intl)}
                  caption={<FormattedMessage id="hardwaredetailpage_current_reservation" />}
                />
              ) : (
                <Alert status="info">
                  <AlertIcon />
                  <FormattedMessage id="hardwaredetailpage_no_current_reservation" />
                </Alert>
              )}
            </TabPanel>

            <TabPanel>
              <ButtonGroup alignItems="flex-end" variant="outline" marginBottom={2}>
                <Button
                  onClick={connectModalDisclosure.onOpen}
                  isDisabled={
                    !hardware.guard.canConnect ||
                    !isRequiredAccessLevelForAction(hardware.accessLevel)
                  }
                >
                  <FormattedMessage id="hardwaredetailgrid_connect" />
                </Button>
                <Button
                  onClick={handleFree}
                  isDisabled={
                    !hardware.guard.canFree || !isRequiredAccessLevelForAction(hardware.accessLevel)
                  }
                  isLoading={isLoadingFree}
                >
                  <FormattedMessage id="hardwaredetailgrid_free" />
                </Button>
              </ButtonGroup>
              {hardware.status.proxy.active ? (
                <AttributesTable
                  attributes={getTableAttributes('proxy-all', hardware, intl)}
                  caption={<FormattedMessage id="hardwaredetailpage_current_proxy" />}
                />
              ) : (
                <Alert status="info">
                  <AlertIcon />
                  <FormattedMessage id="hardwaredetailpage_no_current_proxy" />
                </Alert>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      {actionResult && (
        <HardwareActionModal
          onClose={handleClearActionResult}
          result={actionResult}
          hardware={hardware}
          refresh={refresh}
        />
      )}
      {connectModalDisclosure.isOpen && (
        <ConnectModal {...connectModalDisclosure} hardware={hardware} refresh={refresh} />
      )}
    </>
  );
}
