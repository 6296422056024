import React, { useState } from 'react';
import { Flex, Heading, Box } from '@chakra-ui/layout';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/accordion';
import { Icon } from '@chakra-ui/icons';
import { FiCommand } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import { Select, Button, ButtonGroup } from '@chakra-ui/react';
import { Action, ActionOption } from '../../apiClient/types';
import { getHardwareActionMessages } from '../hardware/getHardwareActionMessages';
import { isRequiredAccessLevelForAction } from '../hardware/isRequiredAccessLevelForAction';

type Props = {
  hardwareAction: Action;
  isLoading: boolean;
  onStartAction: (action: Action, option?: string | undefined) => void;
  isReserved: boolean;
};

export function HardwareActionContainer(props: Props) {
  const [selectedOption, setSelectedOption] = useState<string | undefined>();
  const intl = useIntl();
  const hardwareActionMessages = getHardwareActionMessages(props.hardwareAction.id, intl);

  function handleChangeOption(event: React.ChangeEvent<HTMLSelectElement>) {
    if (event.target.value === '') setSelectedOption(undefined);
    else setSelectedOption(event.target.value);
  }

  return (
    <Accordion allowToggle>
      <AccordionItem key={props.hardwareAction.id}>
        <AccordionButton>
          <Flex w="100%" justifyContent="space-between">
            <Flex p={2}>
              <Icon as={FiCommand} mr={4} boxSize={8} color="blue.300"></Icon>
              <Box textAlign="left">
                <Heading as="h5" size="sm">
                  {props.hardwareAction.name}
                </Heading>
                {hardwareActionMessages.description}
              </Box>
            </Flex>
          </Flex>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          {props.hardwareAction.options && (
            <Select
              placeholder={intl.formatMessage({ id: 'commons_options' })}
              onChange={handleChangeOption}
              disabled={!isRequiredAccessLevelForAction(props.hardwareAction.accessLevel)}
            >
              {props.hardwareAction.options.map(({ name, id }: ActionOption) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </Select>
          )}
          <ButtonGroup style={{ justifyContent: 'right', display: 'flex', marginTop: 2 }}>
            <Button
              type="submit"
              onClick={() => props.onStartAction(props.hardwareAction, selectedOption)}
              variant="solid"
              isLoading={props.isLoading}
              disabled={
                !isRequiredAccessLevelForAction(props.hardwareAction.accessLevel) ||
                props.isLoading ||
                props.isReserved ||
                (props.hardwareAction.options && !selectedOption)
              }
            >
              {hardwareActionMessages.execute}
            </Button>
          </ButtonGroup>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
