import { Badge } from '@chakra-ui/layout';
import { Hardware, ReservationStatusEnum } from '../../apiClient/types';
import { FormattedMessage } from 'react-intl';

export function ReservationStatusBadge(props: { hardware: Hardware }) {
  switch (props.hardware.status.reservation.status) {
    case ReservationStatusEnum.FREE:
      return (
        <Badge variant="outline" colorScheme="gray">
          <FormattedMessage id="reservationstatusbadge_free" />
        </Badge>
      );
    case ReservationStatusEnum.RESERVED_BY_ME:
      return (
        <Badge variant="solid" colorScheme="green">
          <FormattedMessage id="reservationstatusbadge_reserved_by_me" />
        </Badge>
      );
    case ReservationStatusEnum.RESERVED:
      return (
        <Badge variant="solid" colorScheme="red">
          <FormattedMessage id="reservationstatusbadge_reserved" />
        </Badge>
      );
  }
}
