import { useEffect, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Text } from '@chakra-ui/layout';
import { Select } from '@chakra-ui/select';
import { FormControl, FormHelperText, FormLabel } from '@chakra-ui/form-control';
import { Button } from '@chakra-ui/button';
import {
  CompatibilityCodeEnum,
  Hardware,
  HardwareProxyParameters,
  ProxyManager,
} from '../../apiClient/types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useConnectHardware } from './useConnectHardware';
import { useLoadProxyManagers } from './useLoadProxyManagers';
import { useLoadProxyParametersDefinition } from './useLoadProxyParametersDefinition';
import { ProxyParametersForm } from './ProxyParametersForm';
import { CompatibilityAlert } from './CompatibilityAlert';

type Props = {
  isOpen: boolean;
  onOpen: () => unknown;
  onClose: () => unknown;
  hardware: Hardware;
  refresh: () => unknown;
};

export function ConnectModal(props: Props) {
  const intl = useIntl();
  const {
    loadProxyManagers,
    proxyManagers,
    isLoading: isLoadingProxyManagers,
  } = useLoadProxyManagers();
  const [selectedProxyManager, setSelectedProxyManager] = useState<ProxyManager>();
  const [proxyParameters, setProxyParameters] = useState<HardwareProxyParameters>();

  function handleChangeProxyManager(event: { target: { value: string } }) {
    setSelectedProxyManager(
      proxyManagers.find(({ identifier }) => identifier === event.target.value)
    );
  }

  const { handleConnect, isLoading: isLoadingSubmit } = useConnectHardware(
    props.hardware,
    handleConnectSuccess,
    selectedProxyManager?.identifier,
    proxyParameters
  );

  const { loadProxyParametersDefinition, proxyParametersDefinition } =
    useLoadProxyParametersDefinition(props.hardware, selectedProxyManager);

  function handleConnectSuccess() {
    props.onClose();
    props.refresh();
  }

  useEffect(() => void loadProxyManagers(), [loadProxyManagers]);
  useEffect(() => void loadProxyParametersDefinition(), [loadProxyParametersDefinition]);

  return (
    <Modal isOpen onClose={props.onClose}>
      <ModalOverlay />
      <form onSubmit={handleConnect}>
        <ModalContent>
          <ModalHeader>
            <FormattedMessage id="connectionmodal_header" />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={8}>
              <FormattedMessage id="connectionmodal_description" />
            </Text>
            <FormControl mb={6} id="proxy">
              <FormLabel>
                <FormattedMessage id="connectionmodal_proxy_title" />
              </FormLabel>

              <Select
                placeholder={intl.formatMessage({ id: 'connectionmodal_select_proxy' })}
                value={selectedProxyManager?.identifier}
                onChange={handleChangeProxyManager}
                isDisabled={isLoadingProxyManagers}
              >
                {proxyManagers.map(({ identifier, displayName }) => (
                  <option key={identifier} value={identifier}>
                    {displayName}
                  </option>
                ))}
              </Select>
              <FormHelperText>
                <FormattedMessage id="connectionmodal_select_proxy_helpertext" />
              </FormHelperText>
            </FormControl>
            {selectedProxyManager && proxyParametersDefinition && (
              <ProxyParametersForm
                definition={proxyParametersDefinition}
                value={proxyParameters}
                onChange={setProxyParameters}
              />
            )}
            {selectedProxyManager && <CompatibilityAlert proxyManager={selectedProxyManager} />}
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" mr={3} onClick={props.onClose}>
              <FormattedMessage id="connectionmodal_cancel" />
            </Button>

            <Button
              type="submit"
              isDisabled={
                selectedProxyManager?.compatibilityCode !== CompatibilityCodeEnum.OK ?? true
              }
              variant="solid"
              isLoading={isLoadingSubmit}
            >
              <FormattedMessage id="connectionmodal_connect" />
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}
