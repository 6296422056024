import { useState, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useApiClient } from '../../apiClient/useApiClient';
import { useShowError } from '../../useShowError';
import type { Hardware, Mailbox } from '../../apiClient/types';
import { useWebSocket } from '../../webSocket/useWebSocket';

export function useLoadHardware() {
  const { latestWsMessage } = useWebSocket();
  const [hardwares, setHardwares] = useState<Hardware[]>();
  const [isLoading, setLoading] = useState(true);
  const showError = useShowError();
  const { listHardware, getSingleHardware } = useApiClient();
  const intl = useIntl();

  const loadHardware = useCallback(
    async function () {
      setLoading(true);
      try {
        setHardwares(await listHardware());
      } catch (error) {
        showError(error, {
          description: intl.formatMessage({ id: 'hardwarepage_hardware_list_error' }),
        });
      }
      setLoading(false);
    },
    [showError, listHardware, intl]
  );

  const reloadSingleHardware = useCallback(
    async function ({
      hardwareServer,
      serialNumber,
    }: {
      hardwareServer: Mailbox['identifier'];
      serialNumber: Hardware['serialNumber'];
    }) {
      const { hardware } = await getSingleHardware({ hardwareServer, serialNumber });
      setHardwares((prevHardwares) =>
        prevHardwares?.map((prevHardware) => {
          if (
            prevHardware.serialNumber === serialNumber &&
            prevHardware.server.identifier === hardwareServer
          ) {
            return hardware;
          } else {
            return prevHardware;
          }
        })
      );
    },
    [getSingleHardware]
  );

  useEffect(() => {
    if (latestWsMessage && ['hardwareStateChange'].includes(latestWsMessage.subject)) {
      reloadSingleHardware({
        hardwareServer: latestWsMessage.server,
        serialNumber: latestWsMessage.hardware,
      });
    }
  }, [latestWsMessage, reloadSingleHardware]);

  return { loadHardware, isLoading, hardwares, setHardwares };
}
