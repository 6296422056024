import { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useShowOutOfDate } from '../useShowOutOfDate';
import { useApiClient } from '../apiClient/useApiClient';

export type WebSocketMessage = {
  server: string;
  hardware: string;
  subject: string;
};

export function useWebSocket() {
  const webSocket = useRef<WebSocket | null>(null);
  const [latestWsMessage, setLatestWsMessage] = useState<WebSocketMessage>();
  const { connectWebSocket } = useApiClient();
  const intl = useIntl();
  const showOutOfDate = useShowOutOfDate();

  useEffect(() => {
    if (!connectWebSocket) return;

    webSocket.current = connectWebSocket();

    webSocket.current.onmessage = (message: MessageEvent) => {
      if (webSocket.current) {
        const parsedMessage = JSON.parse(message.data);
        if (parsedMessage.subject === 'serverListChange') {
          showOutOfDate({ description: intl.formatMessage({ id: 'useshowoutofdate_refresh' }) });
        }
        if (parsedMessage.subject === 'mqttDown') {
          showOutOfDate({ description: intl.formatMessage({ id: 'useshowoutofdate_mqtt_down' }) });
        }
        setLatestWsMessage(parsedMessage);
      }
    };

    function disconnect() {
      webSocket.current?.close();
      webSocket.current = null;
    }

    webSocket.current.onerror = disconnect;

    return disconnect;
  }, [intl, showOutOfDate, connectWebSocket]);

  return { webSocket: webSocket.current, latestWsMessage };
}
