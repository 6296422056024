import { Grid, GridItem } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { Layout } from '../../layout/Layout';
import { PageHeading } from '../../layout/PageHeading';
import { InstructionsAccordion } from './InstructionsAccordion';
import { SectionLink } from './SectionLink';

export function DashboardPage() {
  return (
    <Layout>
      <PageHeading
        heading={<FormattedMessage id="dashboardpage_heading" />}
        description={<FormattedMessage id="dashboardpage_description" />}
      />
      <Grid gap={4} autoFlow="true" autoRows="true" templateColumns="repeat(2, 1fr)">
        <GridItem>
          <SectionLink
            to="/hardware"
            heading="Hardware"
            description="The Hardware section lists the connected hardware that is available for you to connect to."
          />
        </GridItem>
        <GridItem rowSpan={5} colSpan={1}>
          <InstructionsAccordion />
        </GridItem>
        <GridItem colSpan={1}>
          <SectionLink
            to="/download"
            heading="Downloads"
            description="The downloads section currently contains the Application proxy script you need for connecting to the available hardware."
          />
        </GridItem>
        <GridItem colSpan={1}>
          <SectionLink
            external
            to="/sys/admin"
            heading="System Administration"
            description="Open the System Administration Console in a new browser tab"
          />
        </GridItem>
        <GridItem colSpan={1}>
          <SectionLink
            heading="Assets"
            description="The Assets section will contain the uploaded items that can be used for hardware preparation, test configurations and mobile applications. It will be available in a future release."
            disabled
          />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <SectionLink
            heading="Mobile devices"
            description="The Mobile devices section will contain the mobile devices available for automated testing of applications. It will be available in a future release."
            disabled
          />
        </GridItem>
      </Grid>
    </Layout>
  );
}
