import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';
import { mode, StyleFunctionProps } from '@chakra-ui/theme-tools';
// @ts-expect-error missing types, see: https://github.com/ceteio/chakra-capsize/pull/3
import { withCappedText } from '@ceteio/chakra-capsize/theme';
import latoFontMetrics from '@capsizecss/metrics/lato';

export const theme = extendTheme(
  {
    colors: {
      brand: {
        50: '#dff3f5',
        100: '#b8e3e9',
        200: '#a5dce3',
        300: '#91d4dd',
        400: '#7eccd7',
        500: '#57bdcb',
        600: '#38a7b7',
        700: '#3295a3',
        800: '#2c8490',
        900: '#206069',
      },
    },
    config: {
      initialColorMode: 'light',
      useSystemColorMode: false,
    },
    fonts: {
      heading: `Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
      body: `Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    },
    styles: {
      global: (props: StyleFunctionProps) => ({
        body: {
          color: mode('gray.700', 'whiteAlpha.800')(props),
          backgroundColor: mode('gray.100', 'gray.800')(props),
        },
        '.darkGrayText': {
          color: 'gray.800',
        },
        '#root': {
          minH: '100vh',
        },
      }),
    },
  },
  withCappedText({
    fontMetrics: {
      Lato: latoFontMetrics,
    },
  }),
  withDefaultColorScheme({ colorScheme: 'brand' })
);
