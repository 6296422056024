import { IntlShape } from 'react-intl';
import { Hardware } from '../../apiClient/types';
import { isEntryArray } from './isEntryArray';
import { AttributeTypeEnum } from '../../apiClient/types';

export function getTableAttributes(type: string, hardware: Hardware, intl: IntlShape) {
  switch (type) {
    case 'product':
      return hardware.product
        ? [
            [intl.messages.hardwaredetailgrid_info_name_title, hardware.product.name],
            ...buildProductAttributes(hardware),
          ].filter(isEntryArray)
        : [[intl.messages.hardwaredetailgrid_info_name_title, hardware.product], []].filter(
            isEntryArray
          );
    case 'hardware':
      return [
        [intl.messages.hardwaredetailgrid_host_title, hardware.server.host],
        [intl.messages.hardwaredetailgrid_info_name_title, hardware.name],
        [intl.messages.hardwaredetailgrid_info_serialNumber_title, hardware.serialNumber],
        ...buildHardwareAttributes(hardware),
      ].filter(isEntryArray);
    case 'reservation':
      return [
        [
          intl.messages.hardwaredetailgrid_status_reservedBy_title,
          hardware.status.reservation ? hardware.status.reservation.reservedBy : [],
        ],
        [
          intl.messages.hardwaredetailgrid_status_reservedOn_title,
          hardware.status.reservation ? hardware.status.reservation.reservedDate : [],
          null,
          AttributeTypeEnum.DATETIME,
        ],
        [
          intl.messages.hardwaredetailgrid_status_reservationMessage_title,
          hardware.status.reservation ? hardware.status.reservation.message : [],
        ],
      ].filter(isEntryArray);
    case 'proxy':
      return [
        [
          intl.messages.hardwaredetailgrid_proxy_manager_title,
          hardware.status.proxy.proxyManagerName,
        ],
        [intl.messages.hardwaredetailgrid_proxy_network_title, hardware.status.proxy.hostNetwork],
        ...buildProxyProxies(hardware),
      ].filter(isEntryArray);
    case 'proxy-all':
      return [
        [
          intl.messages.hardwaredetailgrid_proxy_manager_title,
          hardware.status.proxy.proxyManagerName,
        ],
        [intl.messages.hardwaredetailgrid_proxy_network_title, hardware.status.proxy.hostNetwork],
        [
          intl.messages.hardwaredetailgrid_proxy_address_title,
          hardware.status.proxy.hostPrimaryAddress,
        ],
        ...buildProxyAddresses(hardware, intl),
        ...buildProxyProxies(hardware),
      ].filter(isEntryArray);
    default:
      return [];
  }
}

function buildProductAttributes(hardware: Hardware) {
  return Object.entries(hardware.product.attributes).map((entry, index) => [
    entry[0],
    entry[1],
    'product_attribute_' + index,
  ]);
}

function buildHardwareAttributes(hardware: Hardware) {
  return Object.entries(hardware.attributes).map((entry, index) => [
    entry[0],
    entry[1],
    'hardware_attribute_' + index,
  ]);
}

function buildProxyProxies(hardware: Hardware) {
  if (!hardware.status.proxy.proxies) return [];
  return hardware.status.proxy.proxies.flatMap((proxy, index) => [
    [proxy.type, proxy.address, 'proxy_proxy_' + index],
  ]);
}

function buildProxyAddresses(hardware: Hardware, intl: IntlShape) {
  if (!hardware.status.proxy.hostAllAddresses) return [];
  return hardware.status.proxy.hostAllAddresses.flatMap((address, index) => [
    [
      index === 0 ? intl.messages.hardwaredetailgrid_proxy_all_addresses_title : '',
      address,
      'proxy_address_' + index,
    ],
  ]);
}
