import { useEffect } from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import { Text, Heading, VStack, HStack } from '@chakra-ui/layout';
import { Layout } from '../../layout/Layout';
import { Spinner } from '@chakra-ui/spinner';
import { FormattedMessage } from 'react-intl';

export function LogoutPage() {
  const { instance } = useMsal();
  const isLoggedIn = useIsAuthenticated();

  useEffect(() => {
    if (isLoggedIn) {
      instance.logoutRedirect();
    }
  }, [isLoggedIn, instance]);

  return (
    <Layout>
      <VStack align="start">
        <HStack spacing={4}>
          <Heading>
            <FormattedMessage id="logoutpage_heading" />
          </Heading>
          <AuthenticatedTemplate>
            <Spinner color="brand.500" size="sm" />
          </AuthenticatedTemplate>
        </HStack>
        <UnauthenticatedTemplate>
          <Text>
            <FormattedMessage id="logoutpage_description" />
          </Text>
        </UnauthenticatedTemplate>
      </VStack>
    </Layout>
  );
}
